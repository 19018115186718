<template>
  <div class="main-body">
    <div class="left-qys">
      <el-form class="moduleForm">
        <el-form-item label="公司主体" class="first-item">
          <el-select
              v-model="searchData.company"
              clearable
              filterable
              placeholder="选择公司主体"
              style="width: 300px"
              @change="handeChangeCompany">
            <el-option
                v-for="item in companyList"
                :key="item.name"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同模板" class="first-item">
          <el-select
              v-model="searchData.template"
              clearable
              filterable
              placeholder="选择合同模板"
              style="width: 300px"
              @change='handeChangeTemplate'>
            <el-option
                v-for="item in templateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最晚签署日期">
          <el-date-picker
              v-model="searchData.date"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择最晚签署日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="tool">
        <div class="tool-title">大写金额转换工具</div>
        <div class="tool-flex">
          <div class="tool-left">
            <el-input placeholder="请输入数字金额" clearable @input="digitUppercase" v-model="money"
                      class="input-money"></el-input>
          </div>
          <div class="tool-right" id="tool">
            <el-input placeholder="大写金额显示位置" v-model="money_big" class="input-with-select">
              <el-button slot="append" @click="copyValue">复制</el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="left-content">
        <div class="contract-title">参数</div>
        <div v-if="dataParametersList.length>0" class="contact-content"
             style="max-height: calc(100% - 133px)">
          <div class="contact">
            <div class="contact-info">
              <el-form>
                <el-form-item v-for="item in dataParametersList" :key="item.name">
                  <div v-if="item.type==='htmlText'" class="contact-form-item">
                    <div class="label">{{ item.name }}</div>
                    <div class="form-content">
                      <el-input v-model="item.value" type="textarea" :placeholder="'输入'+ item.name"
                                clearable></el-input>
                    </div>
                  </div>
                  <div v-if="item.type==='text'" class="contact-form-item">
                    <div class="label">{{ item.name }}</div>
                    <div class="form-content">
                      <el-input v-model="item.value" :placeholder="'输入'+ item.name" clearable></el-input>
                    </div>
                  </div>
                  <div v-if="item.type==='radio'" class="contact-form-item">
                    <div class="label">{{ item.name }}</div>
                    <div class="form-content radio-padding">
                      <div v-for="i in item.options">
                        <el-radio v-model="item.value" :label="i.label" :value="i.label" border>{{ i.label }}</el-radio>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type==='checkbox'" class="contact-form-item">
                    <div class="label">{{ item.name }}</div>
                    <div class="form-content radio-padding">
                      <div v-for="i in item.options">
                        <el-checkbox :key="i.id" v-model="item.isCheck.find(o=>o.id===i.id).check" :label="i.label"
                                     :value="i.id" border>{{ i.label }}
                        </el-checkbox>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type==='person'" class="contact-form-item">
                    <div class="label" style="margin-right: 0">{{ item.name }}</div>
                    <div class="form-content" style="padding-left: 15px;overflow-x:auto">
                      <div class="box-code-info" style="flex-wrap: nowrap;">
                        <div v-for="i in 18" :style="{borderRight: i===18?'1px solid #DCDFE6':''}" class="box-code">
                          {{ item.value[i - 1] }}
                          <div
                              :style="{display:codeBlur&&item.value.length!==0&&i===item.value.length&&item.value.length!==18?'block':'none'}"
                              class="holder"></div>
                        </div>
                        <div :style="{display:showFirst?'block':'none',left:'14px',top:'14px'}" class="holder"></div>
                        <input
                            v-model="item.value"
                            :maxlength="18"
                            class="input-code"
                            type="tel"
                            @blur="codeInputBlur(item.value)"
                            @focus="codeInputFocus(item.value)"
                            @input="codeInputChange(item.value)"/>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type==='select'" class="contact-form-item">
                    <div class="label">{{ item.name }}</div>
                    <div class="form-content">
                      <el-select
                          v-model="item.value"
                          clearable
                          filterable
                          :placeholder="'选择'+item.name"
                          style="width: 300px">
                        <el-option
                            v-for="item in item.options"
                            :key="item.id"
                            :label="item.label"
                            :value="item.label">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div v-else class="contract-emtry">
          <div class="emtry-center">
            <img src="../../static/emtry.png">
            <div class="emtry-info">暂无数据</div>
          </div>
        </div>
        <div class="footer footer-fix">
          <el-button size="mini" @click="close">关闭</el-button>
          <!--          <el-button size="mini" type="primary" :loading="previewLoad" @click="preview">预览</el-button>-->
          <el-button size="mini" type="primary" :loading="saveLoad" style="width: auto" @click="preview">保存并预览
          </el-button>
        </div>
      </div>
    </div>
    <div class="right-qys">
      <div class="contract-title">合同预览</div>
      <div v-if="pdfUrl" class="contact-content" style="height: calc(100% - 45px);position: relative">
        <div
            v-if="pdfLoad"
            v-loading="pdfLoad"
            class="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中,请稍等"></div>
        <pdf v-for="i in numPages" :key="i" :page="i" :src="pdfUrl"></pdf>
      </div>
      <div v-else class="pdfEmtry">
        <div class="emtry-center">
          <img src="../../static/emtry.png">
          <div class="emtry-info">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import pdf from 'vue-pdf'
import {
  CreateStandardContract,
  GetCompanyentity,
  GetContractPreviewData,
  GetConttemplate,
  GetStandard,
  GetTemplateParametersList
} from "@/api";
import {isInterceptorCode} from "@/common/commonFuncs";

export default {
  name: 'HomeView',
  components: {pdf},
  data() {
    return {
      searchData: {//下拉列表数据选择回显
        company: '',
        template: '',
        date: ''
      },
      radio: '',
      checked1: '',
      checked2: '',
      numPages: 1,//pdf页数
      pdfUrl: '',
      pdfLoad: false,//加载pdf状态
      showFirst: false,//是否显示第一个光标
      companyList: [],//公司主体
      templateList: [],//合同模板
      requestid: '',
      dataParametersList: [],//右侧参数列表
      codeBlur: false,//身份证号input聚焦状态
      previewLoad: false,//预览loading状态
      saveLoad: false,//保存loading状态
      money: '', // 数字金额
      money_big: '' // 金额大写
    }
  },
  methods: {
    // 预览右侧数据
    preview() {
      let para = {
        requestid: this.requestid,//流程id
        companyName: this.searchData.company,//公司主体
        caregoryId: this.searchData.template,//合同模板
        templateParams: this.dataParametersList,//参数
        expireTime: this.searchData.date,//最晚签约时间
      }
      if (!para.requestid) {
        this.$message.warning('流程ID不能为空')
        return
      }
      if (!para.companyName) {
        this.$message.warning('请选择公司主体')
        return
      }
      if (!para.caregoryId) {
        this.$message.warning('请选择合同模板')
        return
      }
      if (!para.expireTime) {
        this.$message.warning('最晚签署日期不能为空')
        return
      }
      let required = []
      let unRequired = []
      let regex = 11 && /^1[3456789]\d{9}$/
      let check_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
      required = para.templateParams.filter(i => i.required === true)
      unRequired = para.templateParams.filter(i => i.required === false)
      for (let i = 0; i < required.length; i++) {
        const item = required[i]
        if (item.type === 'htmlText') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'text') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.name === '手机' && item.value !== '') {
          if (!regex.test(item.value)) {
            this.$message.error('手机号格式不正确!')
            return
          }
        }
        if (item.name === '邮箱' && item.value !== '') {
          if (!check_email.test(item.value)) {
            this.$message.error('邮箱格式不正确!')
            return
          }
        }
        if (item.type === 'radio') {
          if (item.value === '') {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
        if (item.type === 'person') {
          if (item.value === '') {
            this.$message.warning('请输入' + item.name + '!')
            return
          }
        }
        if (item.type === 'person' && item.value) {
          if (!this.validateIDCard(item.value)) {
            this.$message.error('身份证号码格式不正确！')
            return
          }
        }
        if (item.type === 'checkbox') {
          // 判断 多选选项状态中是否都为false
          let checkStatus = item.isCheck.every(obj => obj.check === false);
          if (checkStatus) {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
        if (item.type === 'select') {
          if (item.value === '') {
            this.$message.warning('请选择' + item.name + '!')
            return
          }
        }
      }
      for (let x = 0; x < unRequired.length; x++) {
        const unItem = unRequired[x]
        if (unItem.name === '手机' && unItem.value !== '') {
          if (!regex.test(unItem.value)) {
            this.$message.error('手机号格式不正确!')
            return
          }
        }
        if (unItem.name === '邮箱' && unItem.value !== '') {
          if (!check_email.test(unItem.value)) {
            this.$message.error('邮箱格式不正确!')
            return
          }
        }
        if (unItem.type === 'person' && unItem.value) {
          if (!this.validateIDCard(unItem.value)) {
            this.$message.error('身份证号码格式不正确！')
            return
          }
        }
      }
      this.saveLoad = true
      CreateStandardContract(para).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功')
          this.saveLoad = false
          let para = {
            requestid: this.requestid,//地址拦获取
            companyName: this.searchData.company,//下拉列表获取
            caregoryId: this.searchData.template,//下拉列表获取
          }
          if (para.companyName === '') {
            this.$message.warning('请选择公司主体')
            return
          }
          if (para.caregoryId === '') {
            this.$message.warning('请选择合同模板')
            return
          }
          this.previewLoad = true
          GetStandard(para).then(res => {
            if (res.code === 200) {
              this.previewLoad = false
              this.pdfUrl = res.msg
              this.getNumPages(res.msg)
            } else {
              this.previewLoad = false
              if (!isInterceptorCode(res.code)) {
                this.$message.error(res.msg)
              }
            }
          }).catch(() => {
            this.previewLoad = false
            this.$message.error('服务器连接失败!')
          })
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
            this.saveLoad = false
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
        this.saveLoad = false
      })
    },
    close() {
      window.close()
    },
    codeInputBlur(code) {
      this.codeBlur = false
      if (code.length === 0) {
        this.showFirst = false
      }
    },
    codeInputFocus(code) {
      this.codeBlur = true
      if (code.length === 0) {
        this.showFirst = true
      }
    },
    codeInputChange(code) {
      if (code.length > 0) {
        this.showFirst = false
      } else {
        this.showFirst = true
      }
    },
    // 改变公司主体
    handeChangeCompany() {
      this.getContractTemplate()
      this.getstandardPdf()
    },
    // 改变合同模板
    handeChangeTemplate() {
      this.getstandardPdf()
    },
    //获取PDF文件的页数
    onNumPagesChange(numPages) {

    },
    //已加载的页面索引
    onPageLoaded(pageIndex) {

    },
    //处理加载PDF时的错误
    onError(error) {

    },
    // 整个页面PDF效果
    getNumPages(url) {
      let loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        this.pdfLoad = false
      }).catch((err) => {
        this.pdfLoad = false
        this.$message.error('pdf加载失败!')
      })
    },
    // 获取公司主体下拉列表
    getCompany() {
      GetCompanyentity().then(res => {
        if (res.code === 200) {
          this.companyList = res.data
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取合同模板下拉列表
    getContractTemplate() {
      GetConttemplate({companyName: this.searchData.company}).then(res => {
        if (res.code === 200) {
          this.templateList = res.data
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取左侧制式pdf
    getstandardPdf() {
      let para = {
        requestid: this.requestid,//地址拦获取
        companyName: this.searchData.company,//下拉列表获取
        caregoryId: this.searchData.template,//下拉列表获取
      }
      if (para.companyName === '') {
        this.$message.warning('请选择公司主体')
        return
      }
      if (para.caregoryId === '') {
        this.$message.warning('请选择合同模板')
        return
      }
      GetStandard(para).then(res => {
        if (res.code === 200) {
          this.pdfUrl = res.msg
          this.getNumPages(res.msg)
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
      this.getParameter()
    },
    // 获取右侧参数
    getParameter() {
      let para = {
        requestid: this.requestid,//地址拦获取
        companyName: this.searchData.company,//下拉列表获取
        caregoryId: this.searchData.template,//下拉列表获取
      }
      GetTemplateParametersList(para).then(res => {
        if (res.code === 200) {
          this.dataParametersList = res.data
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
    validateIDCard(idCard) {
      // 正则表达式
      const regExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      // 判断是否匹配正则表达式
      if (regExp.test(idCard) === false) {
        return false;
      }

      // 验证最后一位校验码
      if (idCard.length === 18) {
        const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
        const checkCodes = '10X98765432'; // 校验码对应值
        let sum = 0;
        for (let i = 0; i < 17; i++) {
          sum += parseInt(idCard[i]) * weights[i];
        }
        const mod = sum % 11;
        const checkCode = checkCodes.charAt(mod);
        if (idCard.charAt(17).toUpperCase() !== checkCode) {
          return false;
        }
      }

      return true;
    },
    // 转换金额
    digitUppercase() {
      let n = this.money
      const fraction = ['角', '分'];
      const digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖',
      ];
      const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
      const head = n < 0 ? '欠' : '';
      n = Math.abs(n);
      let s = '';
      for (let i = 0; i < fraction.length; i++) {
        s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
      }
      s = s || '整';
      n = Math.floor(n);
      for (let i = 0; i < unit[0].length && n > 0; i++) {
        let p = '';
        for (let j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      this.money_big = head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
    },
    // 复制
    copyValue() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(this.money_big).then(() => {
              this.$message.success('复制成功');
            }, () => {
              this.$message.error('复制失败');
            }
        )
      } else {
        // 创建text area
        const textArea = document.createElement('input')
        textArea.value = this.money_big
        // 使text area不在viewport，同时设置不可见
        document.getElementById("tool").appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((resolve, reject) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
          textArea.remove()
        }).then(() => {
              this.$message.success('复制成功');
            }, () => {
              this.$message.error('复制失败');
            }
        )
      }
    },

    getContractPreviewList() {
      GetContractPreviewData({requestId: this.requestid}).then(res => {
        if (res.code === 200) {
          this.searchData.company = res.data.companyName
          if (this.searchData.company) {
            this.getContractTemplate()
          }
          this.searchData.template = res.data.caregoryId
          if (this.searchData.template) {
            this.getParameter()
            this.getstandardPdf()
          }
          this.searchData.date = res.data.expireTime
        } else {
          if (!isInterceptorCode(res.code)) {
            this.$message.error(res.msg)
          }
        }
      }).catch(() => {
        this.$message.error('服务器连接失败!')
      })
    },
  },
  created() {
    this.getCompany()//获取公司主体下拉
    this.requestid = this.$route.query.requestId ? this.$route.query.requestId : ''
    console.log(this.requestid);
    if (this.requestid) {
      this.getContractPreviewList()
    }
  },
  mounted() {
    this.pdfLoad = true
  }
}
</script>
<style scoped>
.last-item > div:last-child {
  border-bottom: none;
}

.contact {
  margin-right: 18px;
}

.contact-info:last-child .contact-form-item {
  border-bottom: 0;
}

.contact-form-item {
  width: 100%;
  min-height: 50px;

  display: flex;
  font-weight: 400;
  font-size: 14px;
  color: #606266;
}

.label {
  min-height: 50px;
  background: #F4F4F4;
  min-width: 116px;
  flex: 1;
  text-align: right;
  line-height: 50px;
  padding-right: 13px;
  margin-right: 15px;
  border-right: 1px solid #DCDFE6;
}

.form-content {
  /*width: calc(100% - 116px);*/
  min-width: 553px;
  display: flex;
  /*line-height: 50px;*/
  height: 100%;
}

.form-content >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #57C5F7;
  background: #57C5F7;
}

.form-content >>> .el-radio.is-bordered.is-checked {
  border-color: #57C5F7;
}

.form-content >>> .el-radio.is-bordered {
  padding: 8px 10px;
  height: 30px;
}

.form-content >>> .el-radio__input.is-checked + .el-radio__label {
  color: #57C5F7;
}

.form-content >>> .el-radio {
  margin-right: 10px;
}

.form-content >>> .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0px;
}

.form-content >>> .el-checkbox__inner::after {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #FFF;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  transition: transform .15s ease-in;
}

.form-content >>> .el-checkbox__inner {
  border-radius: 50%;
}

.form-content >>> .el-checkbox__inner:hover {
  border-color: #57C5F7;
}

.form-content >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #57C5F7;
  border-color: #57C5F7;
  border-radius: 50%;
  position: relative;
}

.form-content >>> .el-checkbox.is-bordered {
  padding: 5px 10px;
  height: 30px;
  box-sizing: border-box;
}

.form-content >>> .el-checkbox.is-checked {
  border-color: #57C5F7;
}

.form-content >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #57C5F7;
}

.form-content >>> .el-checkbox {
  margin-right: 10px;
}

.form-content >>> .el-button + .el-button, .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.form-content >>> .el-input__inner {
  height: 50px;
}

.box-code-info {
  position: relative;
  display: flex;
  padding: 10px 15px 10px 0;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.box-code {
  border-top: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  height: 28px;
  width: 28px;
  line-height: 30px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
}

.box-code-info > div:last-child {
  border-right: 1px solid #DCDFE6;
}

.input-code {
  width: 540px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: 10px;
  box-sizing: border-box;
  color: transparent;
  background-color: transparent;
  opacity: 0;
}

.form-content >>> .el-input--suffix .el-input__inner {
  margin-top: 3px;
  width: 100%;
  height: 100%;
}

.form-content >>> .el-select {
  width: 98% !important;
}

.form-content >>> .el-select > .el-input {
  height: 46px;
}

.form-content >>> .el-textarea__inner,
.form-content >>> .el-input__inner {
  border: none;
  padding-left: 0;
}

.radio-padding {
  height: 50px;
  line-height: 50px;
}

.loading {
  height: 45px;
  position: absolute;
  top: 50%;
  width: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.holder {
  width: 2px;
  height: 22px;
  background: #b4b4b4;
  animation: holderSnick 600ms infinite alternate;
  display: none;
  position: absolute;
  top: 3px;
  left: 42px;
}

.emtry-info {
  font-size: 14px;
  color: #606266;
  line-height: 20px;
  height: 20px;
}

.contact-info >>> .el-form-item {
  margin-bottom: 0;
  border-top: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
}

.contact-info >>> .el-form-item:nth-child(1) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.contact-info >>> .el-form-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 1px solid #DCDFE6;
}

@keyframes holderSnick {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.right-qys {
  background: #FFFFFF;
  width: calc(50% - 15px);
  height: 100%;
  margin-left: 15px;
  padding: 20px 0px 0 30px;
  box-sizing: border-box;
  border-radius: 2px;
}

.form-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.form-content::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.form-content::-webkit-scrollbar-track {
  background: #F9F9F9;
  border: 1px solid #ECEEF5;
}

.footer-fix >>> .el-button--primary {
  background: #57C5F7;
  border-color: #57C5F7;
  color: #FFFFFF !important;
}

.footer-fix >>> .el-button--mini, .el-button--mini.is-round {
  padding: 6px 12px;
  height: 32px;
  width: 60px;
}

.tool {
  background-color: #FFF;
  padding: 15px 30px;
  margin: 15px 0;
}

.tool .tool-title {
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  line-height: 28px;
  height: 28px;
  margin-bottom: 10px;
}

.tool .tool-flex {
  display: flex;
}

.tool .tool-left {
  width: 200px;
}

.tool .tool-right {
  flex-grow: 1;
}

.tool .tool-left >>> .el-input__inner {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tool .tool-right >>> .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
